




































import { Component, Prop, Vue } from 'vue-property-decorator';

import CheckCircle from '@/assets/icons/CheckCircle.vue';

import ModalDescriptionsPlan from '../ModalDescriptionsPlan/ModalDescriptionsPlan.vue';

import PlansService from '@/services/Plans/PlansService';

import { Plan } from '../../interfaces/IShopping';

const DEFAULT_TITLE = 'Nosso coração está batendo mais acelerado. Seu pagamento foi aprovado.';

@Component({
  components: {
    CheckCircle,
    ModalDescriptionsPlan
  }
})
export default class ThankYou extends Vue {
  @Prop({ default: DEFAULT_TITLE }) title!: string;

  private isLoadingPlan = false;
  private showModal = false;

  private plan: Plan | null = null;

  private PlansService = new PlansService();

  get isPlan() {
    const { feature } = this.$route.params;

    return feature && feature.includes('plano');
  }

  get planId() {
    return Number(this.$route.params.productId);
  }

  created() {
    this.handleFeature();
  }

  async handleFeature() {
    if (this.isPlan) {
      await this.getPlan();
    }
  }

  async getPlan() {
    try {
      this.isLoadingPlan = true;

      const response = await this.PlansService.getPlan({
        planId: Number(this.planId),
        withRelations: 1
      });

      if (response) {
        this.plan = response;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o plano.',
        status: 'error'
      });

      console.error(error);
    } finally {
      this.isLoadingPlan = false;
    }
  }

  redirectToDashboard() {
    this.$router.push({
      name: 'Dashboard'
    });
  }

  setShowModal() {
    this.showModal = true;
  }
}
