































import { Component, Prop, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import Descriptions from '../Descriptions/index.vue';

@Component({
  components: {
    Modal,
    Descriptions
  }
})
export default class ModalDescriptionsPlan extends Vue {
  @Prop() planId!: number;
  @Prop() planTitle!: string;

  handleClickCanceled() {
    this.$emit('cancel');
  }
}
